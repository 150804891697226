<template>
  <div id="app"
    class="flex font-helv text-base leading-normal  font-13px flex-col min-h-screen bg-pageBackground antialiased"
  >
    <div class="background-logo">

    </div>
    <div
      class="block fixed w-full z-20"
    >
      <slot name="navbar"></slot>
    </div>

    <slot name="v-app-content"></slot>

    <app-content-container>
      <div>
        <slot></slot>
      </div>
    </app-content-container>
    <footer class="mt-auto z-5">
      <slot name="footer"></slot>
    </footer>

	  <Modal
      v-if="isShowBannedModal"
      :show-modal="isShowBannedModal"
      @clicked="isShowBannedModal = false"
	  >
      <template v-slot:header>
        {{ trans('MODAL_TITLE:AccountBlocked') }}
      </template>
		  <template v-slot:content>
			  <div class="flex flex-col lg:pl-3">
          <div class="text-base ban-reason -mb-2">
            <div v-html="userData.banReason"></div>
            <div class="w-full flex flex-row justify-end text-s text-grayedDark">
              <span v-if="!isMobile">{{ trans('LABEL:BanDate') }}&nbsp;</span>{{ userData.bannedAt }}
            </div>
          </div>
			  </div>
		  </template>
      <template v-slot:footer>
        <div class="w-full flex flex-col">
          <hr class="w-full border-grayedHR mb-3"/>
          <span class="text-right text-s">
            {{ trans('TEXT:AccountBlockedText2') }} <span @click="goToSpecificRoute(categoriesData, 'contact', {name: 'post-show'}, {banned: true})" class="whitespace-nowrap underline cursor-pointer hover:opacity-70 hover:text-redAccent">{{ trans('TEXT:AccountBlockedText3') }}</span>.
          </span>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import AppContentContainer from '@/layouts/components/AppContentContainer'
import Vue from "vue";
import Modal from "@/utils/modal/Modal.vue";
import {mapGetters, mapMutations} from "vuex";
import {goToSpecificRoute} from "@/common/helpers/utils";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";

export default {
  components: {
	  Modal,
    AppContentContainer,
  },
	data() {
		return {
			goToSpecificRoute: goToSpecificRoute,
			isShowBannedModal: false,
		}
	},
	watch: {
		$route (to, from){
			if(from.name !== 'auth-login') {
				this.isShowBannedModal = false
			}
		},
		userData: {
			immediate: true,
			deep: true,
			handler(val) {
				if (val && val.isBanned && !this.modalBannedAlreadyShowed) {
					this.isShowBannedModal = !this.modalBannedAlreadyShowed;
					this.updateModalBannedAlreadyShown(true);
				}
			}
		}
	},
  computed: {
		...mapGetters('account', ['userData', 'modalBannedAlreadyShowed']),
	  ...mapGetters('dictionaries', ['categoriesData']),
    isMobile() {
      return checkIsMobileDevice();
    },
  },
	methods: {
		...mapMutations('account', ['updateModalBannedAlreadyShown'])
	}

}
</script>

<style lang="scss" scoped>

.background-logo {
  background: theme('colors.pageBackground');
  background-size: 70% auto;
}

.bg-z {
}

@media (min-width: 1024px) {
  .background-logo {
    width: 100vh;
    height: 100vh;
    position:fixed;
    z-index: 0;
    background: url('../../assets/images/logos/logo.png') no-repeat;
    opacity: 0.02;
    top:0;
    left:0;
    background-size: 100vh 100vh;

  }
}

</style>
