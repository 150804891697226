const staticSites = [
    {
        path: '/',
        name: 'main',
        component: () => import('@/views/content/static/Main.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
            allowBanned: true,
            transName: 'Homepage'
        },
    },

]

export default staticSites
