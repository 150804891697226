// initial state
import api from "@/common/services/api";
import toQueryString from "@/common/helpers/toQueryString";
import fromQueryToObject from "@/common/helpers/fromQueryToObject";
import store from '@/store/index'

const state = () => ({
    favouriteUsersData: [],
    loadingFavUsers: false,
    loadingAddFavUser: false,
    loadingRemoveFavUser: false,

})

// getters
const getters = {
    favouriteUsersData: state => state.favouriteUsersData,
    favUsersLoading: state => state.loading,
    loadingAddFavUser: state => state.loadingAddFavUser,
    loadingRemoveFavUser: state => state.loadingRemoveFavUser,
};

//actions
const actions = {
    fetchFavouriteUsers: ({ commit, getters, state, dispatch}) => {
        commit('setLoadingFavUsers', true);

        api.setHeader();
        api.get(`/favourites`)
            .then(
                success => {
                    commit('setFavUsersData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setLoadingFavUsers', false);
        });
    },

    addFavouriteProfile: ({ commit, getters, state, dispatch}, payload) => {
        commit('setLoadingAddFavUser', true);

        api.setHeader();
        api.post(`/add-favourite-user`, payload)
            .then(
                success => {
                    commit('profile/setUserFavouritedBy', true, {root: true})
                    commit('chat/setFavouriteThread', payload, {root: true})
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setLoadingAddFavUser', false);
        });
    },

    removeFavouriteProfile: ({ commit, getters, state, dispatch}, payload) => {
        commit('setLoadingRemoveFavUser', true);

        api.setHeader();
        api.post(`/remove-favourite-user`, payload)
            .then(
                success => {
                    commit('profile/setUserFavouritedBy', false, {root: true})
                    commit('chat/unsetFavouriteThread', payload, {root: true})
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setLoadingRemoveFavUser', false);
        });
    },
}

//mutations
const mutations = {
    setFavUsersData (state, data) {
        state.favouriteUsersData = data;
    },

    setLoadingFavUsers(state, value) {
        state.loadingFavUsers = value;
    },

    setLoadingAddFavUser(state, value) {
        state.loadingAddFavUser = value;
    },
    setLoadingRemoveFavUser(state, value) {
        state.loadingRemoveFavUser = value;
    },
}

export const favourites = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
