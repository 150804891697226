// initial state
import api from "@/common/services/api";
import store from "@/store/index"
import Vue from 'vue';
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import router from "@/router";

const state = () => ({
    threadsData: [],
    messagesData: [],
    messagesPagination: {},

    currentThread: null,
    messageSending: false,
    messageSent: false,
    chosenUser: {},
    threadIdSeen: null,
    notReadCounter: null,
    isInputActive: false,

    // Loading states
    loadingThreads: false,
    loadingMessages: false,
    loadedThreads: false,
    loadedMessages: false,
    loadingBan: false,
    loadedNotReadCounter: false,
    newMessagesLoading: false,

    threadDeleting: false,
    threadDeleted: false,

    echoActive: false,

    errors: false,

    onEnterSend: null,
    dontShowDeletedUsers: null,
    dontShowDeletedUsersDone: false,

    currentMessage: {
        threadId: null,
        message: '',
    },

    banned: false,
})

// getters
const getters = {
    chatActiveMobile: state => state.currentThread !== null && checkIsMobileDevice(),
    threadsData: state => state.threadsData,
    messagesData: state => state.messagesData,
    messagesPagination: state => state.messagesPagination,
    messagesLoaded: state => state.loadedMessages,
    messagesLoading: state => state.loadingMessages,
    threadsLoaded: state => state.loadedThreads,
    threadsLoading: state => state.loadingThreads,
    currentThread: state => state.currentThread,
    messageSending: state => state.messageSending,
    messageSent: state => state.messageSent,
    chosenUser: state => state.chosenUser,
    threadIdSeen: state => state.threadIdSeen,
    notReadCounter: state => state.notReadCounter,
    isInputActive: state => state.isInputActive,
    banLoading: state => state.loadingBan,
    chatErrors: state => state.errors,
    echoActive: state => state.echoActive,
    onEnterSend: state => state.onEnterSend,
    notReadCounterLoaded: state => state.loadedNotReadCounter,
    currentMessage: state => state.currentMessage,
    banned: state => state.banned,
    newMessagesLoading: state => state.newMessagesLoading,
    dontShowDeletedUsers: state => state.dontShowDeletedUsers,
    dontShowDeletedUsersDone: state => state.dontShowDeletedUsersDone,

    threadDeleting: state => state.threadDeleting,
    threadDeleted: state => state.threadDeleted,
};

//actions
const actions = {
    openThreadRequest({commit, state, dispatch}, payload) {
        commit('setLoadingMessages', true);
        commit('setLoadedMessages', false);
        api.setHeader();
        api.get(`/messages`, payload.id)
            .then(
                success => {
                    commit('setMessagesData', {messages: success.data.slice().reverse(), meta: success.meta})
                    commit('setMessagesPagination', success.meta);
                    if(state.currentThread) {
                        commit('clearCurrentThread')
                    }
                    commit('setCurrentThread', payload)
                    if(!!!payload.is_read) {
                        dispatch('changeReadRequest', payload)
                    }
                    commit('setLoadedMessages', true);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setLoadingMessages', false);
        });
    },

    loadMoreMessagesRequest({commit, state, dispatch}, payload) {
        api.setHeader();
        commit('setNewMessagesLoading', true);
        api.get(`/messages`, payload.id + '?page=' + payload.page)
            .then(
                success => {
                    commit('setMessagesPagination', success.meta);
                    commit('setMessagesData', {messages: success.data.slice().reverse(), meta: success.meta})
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setNewMessagesLoading', false);
        });
    },

    fetchMessageThreadsData: ({ commit, dispatch }) => {
        api.setHeader();
        commit('setLoadingThreads', true);
        commit('setLoadedThreads', false);
        api.get(`/message-threads`, '', true)
            .then(
                success => {
                    commit('setThreadsData', success.data)
                    commit('setLoadedThreads', true);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            )
            .finally(() => {
                commit('setLoadingThreads', false);
            });
    },

    sendMessageRequest({commit, dispatch, state}, payload) {
        api.setHeader();
        commit('setMessageSent', false);
        api.post(`/messages`, payload)
            .then(
                success => {
                    commit('setMessageSent', true);
                    commit('sendNewContactMessageFailure', false);
                },
                error => {
                    commit('setMessageSent', false);
                    commit('sendNewContactMessageFailure', true);
                    commit('markMessageAsFailed', payload.tempId)
                    dispatch('alert/error', error, { root: true });
                }
            )
    },

    sendNewContactMessageRequest({commit, dispatch}, payload) {
        api.setHeader();
        commit('setMessageSent', false);
        commit('setMessageSending', true);
        api.post(`/messages`, payload)
            .then(
                success => {
                    commit('setMessageSending', false);
                    commit('setMessageSent', true);
                    commit('sendNewContactMessageFailure', false);
                    commit('profile/setProfileNewContact', true, {root: true});
                    commit('users/setUserProfileThreadId', {threadId: success.data.message.threadId, username: success.data.profileData.username}, {root: true});
                    commit('account/updateRememberedUsers', success.data.rememberedUsers, { root: true });
                    commit('account/updateRememberedNames', success.data.profileData.username, { root: true });
                    commit('profile/setUserRememberedByFalse', null ,{ root: true });
                    commit('profile/setProfileData', success.data.profileData ,{ root: true });
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    commit('sendNewContactMessageFailure', true);
                    commit('setMessageSending', false);
                    dispatch('alert/error', error, { root: true });
                }
            )

    },

    changeReadRequest({commit, dispatch}, payload) {
        api.setHeader();
        api.post(`/message-threads/${payload.id}/change-read`, {
            is_read: true
        })
            .then(
                success => {
                    commit('threadSeen', payload.id)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            )
            .finally(() => {
            });
    },

    getNotReadCount({commit, dispatch}) {
        api.setHeader();
        commit('setLoadedNotReadCounter', false);
        api.get(`/message-threads/not-read-count`)
            .then(
                success => {
                    commit('setNotReadCounter', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            )
            .finally(() => {
                commit('setLoadedNotReadCounter', true);
            })
    },

    banThreadRequest({commit, state, dispatch}, payload) {
        api.setHeader();

        commit('setBanLoading', true);
        commit('setBanned', false);
        api.post(`/message-threads/${payload.threadId}/ban`, payload)
            .then(
                success => {
                    if(state.currentThread && state.currentThread.threadId === payload.threadId) {
                        commit('banCurrentThread', success.data.bannedAt)
                        if(success.data.bannedAt === null) {
                            dispatch('openThreadRequest', state.currentThread)
                        }
                    } else {
                        commit('banThread', success.data)
                    }
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setBanLoading', false);
                commit('setBanned', true);
        })
    },

    sendOnEnterSendRequest({commit, dispatch}, payload) {
        api.setHeader();

        api.post(`/on-enter-send`, payload)
            .then(
                success => {
                    commit('setOnEnterSend', success.data.onEnterSend)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            )
    },

    sendDontShowDeletedUsersRequest({commit, dispatch}, payload) {
        api.setHeader();

        commit('setDontShowDeletedUsersDone', false);
        api.post(`/dont-show-deleted-users`, payload)
            .then(
                success => {
                    commit('setDontShowDeletedUsers', success.data.dontShowDeletedUsers)
                    commit('setDontShowDeletedUsersDone', true);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            )
    },

    chooseColorRequest({commit, dispatch, state}, payload) {
        api.setHeader();

        api.post(`/${state.currentThread.id}/set-color`, payload)
            .then(
                success => {
                    commit('setThreadColor', success.data.color)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            )
    },

    deleteThreadRequest({commit, state, dispatch}, payload) {
        api.setHeader();

        commit('setThreadDeleting', true);
        commit('setThreadDeleted', false);

        api.post(`/message-threads/${payload.threadId}/delete`)
            .then(
                success => {
                    if(state.currentThread.id === payload.threadId) {
                        commit('clearCurrentThread');
                        router.push({name: 'chats'})
                    }
                    commit('deleteThreadFromData', payload.threadId);
                    dispatch('alert/success', success, { root: true });
                    commit('setThreadDeleted', true);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setThreadDeleting', false);
        })
    },

    chooseMessageUserRequest({commit}, payload) {
        commit('setChosenUser', payload);
    },

    clearCurrentThread({commit}) {
        commit('clearCurrentThread');
    },

    setCurrentThreadAction({commit}, data) {
        commit('setCurrentThread', data);
    },
}

//mutations
const mutations = {
    setThreadsData(state, data) {
        state.threadsData = data;
    },

    setMessagesData(state, data) {
        const messages = data.messages;
        const meta = data.meta;
        let preparedMessages = [];
        const userData = store.getters['account/userData'];
        for(let i = 0; i < messages.length; i++) {
            if(i >= 0 && i <= messages.length - 1) {
                if(i === 0) {
                    messages[i]['showImage'] = messages[i].sender.id !== userData.id;
                    preparedMessages.push(messages[i])
                }
                if(i > 0) {
                    messages[i]['showImage'] = messages[i-1].sender.id !== messages[i].sender.id
                    preparedMessages.push(messages[i])
                }
            }
        }
        let newMessages = [];
        if(meta !== undefined && meta.current_page > 1) {
            newMessages = [...preparedMessages, ...state.messagesData]
            state.messagesData = newMessages;
        } else {
            state.messagesData = preparedMessages;
        }

    },

    setMessagesPagination(state, data) {
        state.messagesPagination = data;
    },

    setLoadedMessages (state, value) {
        state.loadedMessages = value;
    },

    setLoadingMessages (state, value) {
        state.loadingMessages = value;
    },

    setLoadedThreads (state, value) {
        state.loadedThreads = value;
    },

    setLoadingThreads (state, value) {
        state.loadingThreads = value;
    },

    setCurrentThread (state, data) {
        state.currentThread = data;
    },

    setMessageSending(state, value) {
        state.messageSending = value;
    },

    setMessageSent(state, value) {
        state.messageSent = value;
    },

    setChosenUser(state, data) {
        state.chosenUser = data;
    },

    clearCurrentThread(state) {
        window.Echo.leave(`chat.${state.currentThread.id}`);
        state.currentThread = null;
    },

    threadSeen(state, id) {
        state.threadIdSeen = id;
    },

    setNotReadCounter(state, data) {
        state.notReadCounter = data;
    },

    setInputState(state, value) {
        state.isInputActive = value;
    },

    setIsCurrentThreadNew(state, value) {
        Vue.set(state.currentThread, 'newThread', value);
    },

    banCurrentThread(state, value) {
        state.currentThread.bannedAt = value;
    },

    setBanLoading(state, value) {
        state.loadingBan = value;
    },

    sendNewContactMessageFailure(state, value) {
        state.errors = value;
    },

    setLoadedNotReadCounter(state, value) {
        state.loadedNotReadCounter = value;
    },

    clearChatDataOnLogout(state) {
        state.threadsData = [];
        state.messagesData = [];
        state.messagesPagination = {};
        state.chosenUser = {};
        state.loadedMessages = false;
        state.loadedThreads = false;
        state.echoActive = false;
    },

    setEchoActive(state, value) {
        state.echoActive = value;
    },

    setOnEnterSend(state, value) {
        Vue.set(store.getters['account/userData'], 'on_enter_send', !!value)
        let userObj = JSON.parse(localStorage.getItem('userData'));
        userObj.on_enter_send = !!value;
        localStorage.setItem('userData', JSON.stringify(userObj));
        state.onEnterSend = !!value;
    },

    setDontShowDeletedUsers(state, value) {
        Vue.set(store.getters['account/userData'], 'dontShowDeletedUsers', !!value)
        let userObj = JSON.parse(localStorage.getItem('userData'));
        userObj.dontShowDeletedUsers = !!value;
        localStorage.setItem('userData', JSON.stringify(userObj));
        state.dontShowDeletedUsers = !!value;
    },

    markThreadAsSeen(state, e) {
        state.threadsData.filter(el => {
            if(el.id === e.threadId) {
                el.threadSeen = true;
            }
        })
    },

    makeLastThreadOwnerMessageUnread(state, threadId) {
        let thread = state.threadsData.find(el => el.id === threadId);
        thread.threadSeen = false;
        thread.lastMessageSender = store.getters['account/userData'].username;
    },

    setCurrentMessageOnChat(state, data) {
        state.currentMessage = {
            threadId: data.threadId,
            message: data.content,
        }
    },

    setNotReadMessagesCounter(state, counter) {
        state.notReadCounter.messageCounter = counter;
    },

    setFavouriteThread(state, data) {
        Vue.set(state.threadsData.find(el => el.targetUser.username === data.favouriteUsername), 'userFavouritedBy', true);
    },

    unsetFavouriteThread(state, data) {
        Vue.set(state.threadsData.find(el => el.targetUser.username === data.favouriteUsername), 'userFavouritedBy', false);
    },

    setBanned(state, value) {
        state.banned = value;
    },

    banThread(state, data) {
        state.threadsData.find(el => el.id === data.threadId).bannedAt = data.bannedAt;
    },

    setThreadColor(state, value) {
        state.currentThread.color = value;
    },

    setNewMessagesLoading(state, value) {
        state.newMessagesLoading = value;
    },

    setDontShowDeletedUsersDone(state, value) {
        state.dontShowDeletedUsersDone = value;
    },

    setCurrentThreadProperty(state, data) {
        Vue.set(state.currentThread, data['property'], data['value']);
    },

    setThreadDeleting(state, value) {
        state.threadDeleting = value;
    },

    setThreadDeleted(state, value) {
        state.threadDeleted = value;
    },

    deleteThreadFromData(state, threadId) {
        state.threadsData.splice(state.threadsData.findIndex(el => el.id === threadId), 1);
    },

    markMessageAsFailed(state, tempId) {
        state.messagesData.find(el => el.tempId === tempId).sendingFailed = true;
    }

}

export const chat = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
