import staticSites from '@/router/app/staticSites';
import authorize from "@/router/app/athorize";
import errors from "@/router/app/errors";
import posts from "@/router/app/posts";
import verifications from "@/router/app/verifications";
import admin from "@/router/app/admin";
import subscriptions from "@/router/app/subscriptions";

const routes = [
    {
        path: '/search',
        name: 'search',
        component: () => import('../../views/content/search/Search'),
        meta: {
            layout: 'content',
            auth: true,
            emailVerified: true,
            allowBanned: false,
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('../../views/content/profile/ProfileOwner.vue'),
        meta: {
            layout: 'content',
            auth: true,
            emailVerified: true,
            allowBanned: false,
        },
    },
    {
        path: '/chats/:id?',
        name: 'chats',
        component: () => import('../../views/content/messages/Messages'),
        meta: {
            layout: 'content',
            auth: true,
            emailVerified: true,
            allowBanned: false,
        },
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('../../views/content/settings/SettingsView'),
        meta: {
            layout: 'content',
            emailVerified: true,
            auth: true,
            allowBanned: false,
        },
    },
    {
        path: '/email-verification',
        name: 'email-verification',
        component: () => import('../../views/VerifyEmail'),
        meta: {
            layout: 'content',
            emailVerified: false,
            auth: false,
            allowBanned: false,
        },
    },
]

export default [
    ...routes,
    ...authorize,
    ...errors,
    ...staticSites,
    ...posts,
    ...verifications,
    ...subscriptions,
    ...admin,
]
