import '@/plugins/vue-composition-api';
import '@/styles/styles.scss';
import '@/styles/slider.scss';
import Vue from 'vue';
import App from './App.vue';
import './plugins/acl';
import router from './router';
import store from './store';
import getTranslation from "@/utils/crud/mixins/getTranslation";
import VueTrix from "vue-trix";
import { EmojiPicker } from 'vue-emoji-picker';
import VueTailwind from 'vue-tailwind';
import vueTailwindConfig from './vueTailwindConfig';
import './styles/tailwind.css';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import './utils/validation/validation.js';
import "./vee-validate";
import scrollSpy, { Easing } from 'vue2-scrollspy';
import FloatingVue from 'floating-vue';
import './plugins/jquery'
import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
import Picture from "@/utils/pictures/Picture";
import 'floating-vue/dist/style.css'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false;

Vue.component('getTranslation', getTranslation);
Vue.component('VueTrix', VueTrix);
Vue.component('EmojiPicker', EmojiPicker);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('Picture', Picture);

Vue.use(scrollSpy, {
  easing: Easing.Sinusoidal.InOut
});
Vue.use(VueTailwind, vueTailwindConfig.settings);
Vue.use(FloatingVue);
Vue.use(VueFileAgent);
Vue.use(CoolLightBox);
Vue.use(VueCookies, { expires: '1d'})


new Vue({
  router,
  store,

  render: h => h(App),
}).$mount('#app');
