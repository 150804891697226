<template>
	<div class="w-full flex flex-col relative bg-white" :class="[classes]">
    <div class="loaderCirclePicture absolute m-auto top-0 bottom-0 left-0 right-0">
		</div>
	</div>
</template>

<script>
export default {
  name: "PictureLoader",
  props: {
	  classes: String,
  },
  data () {
    return {
    }
  },
}
</script>
