const discountCodes = [
    {
        path: '/admin/discount-codes',
        name: 'admin-discount-codes',
        component: () => import('@/views/content/admin/discount-codes/DiscountCodesList.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },
    {
        path: '/admin/discount-codes/new',
        name: 'admin-discount-codes-new',
        component: () => import('@/views/content/admin/discount-codes/DiscountCodeShow.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },
    {
        path: '/admin/discount-codes/:id',
        name: 'admin-discount-codes-show',
        component: () => import('@/views/content/admin/discount-codes/DiscountCodeShow.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },

]

export default discountCodes;
