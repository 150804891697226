import api from "@/common/services/api";

const state = () => ({
    genderData: null,
    genderDeclaredData: null,
    shapeData: null,
    preferenceData: null,
    orientationData: null,
    experiencesData: null,
    statusesMarital: null,
    statusesParental: null,
    searchWhosData: null,
    searchWhatsData: null,
    locationsData: null,
    ratesData: null,
    descriptionStatuses: null,
    categoriesData: null,
    userFiltersData: null,
    stimulantsAndAnimalsData: null,
})

// getters
const getters = {
    genderData: state => state.genderData,
    genderDeclaredData: state => state.genderDeclaredData,
    shapeData: state => state.shapeData,
    preferenceData: state => state.preferenceData,
    orientationData: state => state.orientationData,
    experiencesData: state => state.experiencesData,
    statusesMarital: state => state.statusesMarital,
    statusesParental: state => state.statusesParental,
    searchWhosData: state => state.searchWhosData,
    searchWhatsData: state => state.searchWhatsData,
    locationsData: state => state.locationsData,
    ratesData: state => state.ratesData,
    descriptionStatuses: state => state.descriptionStatuses,
    categoriesData: state => state.categoriesData,
    userFiltersData: state => state.userFiltersData,
    stimulantsAndAnimalsData: state => state.stimulantsAndAnimalsData,
};

//actions
const actions = {
    fetchGenders: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/genders`)
            .then(
                success => {
                    commit('setGenderData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchGenderDeclaredData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/genders-declared`)
            .then(
                success => {
                    commit('setGenderDeclaredData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchShapeData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/shapes`)
            .then(
                success => {
                    commit('setShapeData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchPreferenceData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/preferences`)
            .then(
                success => {
                    commit('setPreferenceData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchOrientationData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/orientations`)
            .then(
                success => {
                    commit('setOrientationData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchExperiencesData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/experiences`)
            .then(
                success => {
                    commit('setExperiencesData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchMaritalData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/statuses-marital`)
            .then(
                success => {
                    commit('setMaritalData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchParentalData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/statuses-parental`)
            .then(
                success => {
                    commit('setParentalData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchSearchWhosData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/search-whos`)
            .then(
                success => {
                    commit('setSearchWhosData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchSearchWhatsData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/search-whats`)
            .then(
                success => {
                    commit('setSearchWhatsData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchLocationData: ({ commit, dispatch}, payload) => {
        api.setHeader();
        api.post(`/dictionaries/locations`, payload)
            .then(
                success => {
                    commit('setLocationData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchRatesData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/rates`)
            .then(
                success => {
                    commit('setRatesData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchDescriptionStatuses: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/desc-statuses`)
            .then(
                success => {
                    commit('setDescriptionStatuses', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchCategoriesData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/categories`)
            .then(
                success => {
                    commit('setCategoriesData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchUserFiltersData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/user-filters`)
            .then(
                success => {
                    commit('setUserFiltersData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchStimulantsAndAnimalsData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/stimulants-and-animals`)
            .then(
                success => {
                    commit('setStimulantsAndAnimalsData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },


}

//mutations
const mutations = {
    setGenderData (state, data) {
        state.genderData = data;
    },

    setGenderDeclaredData (state, data) {
        state.genderDeclaredData = data;
    },

    setShapeData (state, data) {
        state.shapeData = data;
    },

    setPreferenceData (state, data) {
        state.preferenceData = data;
    },

    setOrientationData (state, data) {
        state.orientationData = data;
    },

    setExperiencesData (state, data) {
        state.experiencesData = data;
    },

    setMaritalData (state, data) {
        state.statusesMarital = data;
    },

    setParentalData (state, data) {
        state.statusesParental = data;
    },

    setSearchWhosData (state, data) {
        state.searchWhosData = data;
    },

    setSearchWhatsData (state, data) {
        state.searchWhatsData = data;
    },

    setLocationData (state, data) {
        state.locationsData = data;
    },

    setRatesData (state, data) {
        state.ratesData = data;
    },

    setDescriptionStatuses (state, data) {
        state.descriptionStatuses = data;
    },

    setCategoriesData (state, data) {
        state.categoriesData = data;
    },

    setUserFiltersData (state, data) {
        state.userFiltersData = data;
    },

    setStimulantsAndAnimalsData (state, data) {
        state.stimulantsAndAnimalsData = data;
    },
}

export const dictionaries = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
