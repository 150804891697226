<template>
  <div ref="notifications"
    class="relative flex flex-row items-center z-20"
  >
    <button
      variant="icon"
      class="h-12 inline-flex items-center transition ease-in-out bg-transparent px-0 relative  custom-button
        focus:outline-none hover:opacity-70
        disabled:opacity-70 disabled:cursor-default"
      :disabled="!!isDashboardClear"
      @click.stop="toggleDropdown()"
    >
      <div class="inline-flex items-center">
        <base-icon class="rounded-full h-6 w-6" v-if="dashboardData"
          name="bell" :color="isDashboardClear ? 'fill-grayed' : 'fill-' + dashboardData.dashboardColor"
        ></base-icon>
      </div>
    </button>
    <div v-if="areNotificationsVisible"
      class="absolute top-12 transform right-0 lg:-right-20 z-20 bg-white border-t border-grayedDark border-dashed"
    >
      <!-- Dymek powiadomien -->
			<div v-if="!isDashboardClear"
        class="bg-white text-s text-black border-b"
        :class="$route.name === 'main'?'border-redAccent':'border-black'"
			>
				<ul class="w-full flex flex-col gap-y-2 py-2">
					<template v-if="dashboardData && dashboardData.isBanned"
					>
						<li
              class="inline-flex px-3 items-center gap-2 w-full whitespace-nowrap"
              :class="[getOrderByColor(dashboardData.colors.bannedColor)]"
              role="menuitem"
						>
              <span class="dot flex-none"
                :class="[dashboardData.colors.bannedColor]"
              ></span>
							<Button
                variant="routerLink"
                :to="{name: 'settings'}"
							>
								{{ trans('LABEL:AccountBlocked') }}
							</Button>
						</li>
					</template>
					<template v-if="dashboardData
            && (
              !dashboardData.subscription.isActive
              || dashboardData.subscription.isExpiring
            )"
					>
						<li
              class="inline-flex px-3 items-center gap-2 w-full whitespace-nowrap"
              role="menuitem"
              :class="[getOrderByColor(dashboardData.colors.subscriptionColor)]"
						>
              <span class="dot flex-none"
                :class="[dashboardData.colors.subscriptionColor]"
              ></span>
							<Button variant="routerLink" :to="{name: 'settings'}">
								<template v-if="dashboardData.subscription.isActive && !dashboardData.subscription.isExpiring">
									{{ trans('LABEL:SubscriptionStatusActive') }}
								</template>
								<template v-else-if="dashboardData.subscription.isExpiring">
									<div class="text-left">
										{{ trans('LABEL:SubscriptionStatusActive') }}, <span class="lowercase">{{ dashboardData.subscription.message.text }}</span>
									</div>
								</template>
								<template v-else>
									{{ trans('LABEL:SubscriptionStatusExpired') }}
								</template>
							</Button>
						</li>
					</template>
					<template v-if="dashboardData && !(dashboardData.isVerified)">
						<li
              class="inline-flex px-3 items-center gap-2 w-full"
              :class="[getOrderByColor(dashboardData.colors.verificationColor)]"
              role="menuitem"
						>
							<span class="dot flex-none" :class="[dashboardData.colors.verificationColor]"></span>
							<Button variant="routerLink" :to="{name: 'settings'}">
								{{ trans('LABEL:VerificationIsNotCompleted') }}
							</Button>
						</li>
					</template>
					<template v-if="dashboardData && !dashboardData.isProfileComplete">
						<li
              class="inline-flex px-3 items-center gap-2 w-full"
              :class="[getOrderByColor(dashboardData.colors.profileCompleteColor)]"
              role="menuitem"
						>
							<span class="dot flex-none" :class="[dashboardData.colors.profileCompleteColor]"></span>
							<Button variant="routerLink" :to="{name: 'profile', params: {username: userData.username}}">
								{{ dashboardData.isProfileComplete ? '' : trans('LABEL:ProfileIncomplete') }}
							</Button>
						</li>
					</template>
					<template v-if="dashboardData && dashboardData.messages.counter > 0">
						<li
              class="inline-flex px-3 items-center gap-2 w-full"
              :class="[getOrderByColor(dashboardData.colors.messagesColor)]"
              role="menuitem"
						>
							<span class="dot flex-none" :class="[dashboardData.colors.messagesColor ]"></span>
							<Button variant="routerLink" :to="{name: 'chats'}">
								{{ dashboardData.messages.message }}
							</Button>
						</li>
					</template>
					<template v-if="dashboardData && userData && userData.isHidden">
						<li
              class="inline-flex px-3 items-center gap-2 w-full"
              :class="[getOrderByColor(dashboardData.colors.profileVisibilityColor)]"
              role="menuitem"
						>
							<span class="dot flex-none" :class="[dashboardData.colors.profileVisibilityColor ]"></span>
							<Button variant="routerLink" :to="{name: 'settings'}">
								{{ trans('LABEL:ProfileHidden') }}
							</Button>
						</li>
					</template>
					<template
							v-if="dashboardData && dashboardData.description.dashboardMessage && dashboardData.isProfileComplete">
						<li
              class="inline-flex px-3 items-center gap-2 w-full"
              :class="[getOrderByColor(dashboardData.colors.descriptionColor)]"
              role="menuitem"
						>
							<span class="dot flex-none" :class="[dashboardData.colors.descriptionColor ]"></span>
							<Button variant="routerLink" :to="{name: 'profile', params: {username: userData.username}}">
								{{ dashboardData.description.dashboardMessage }}
							</Button>
						</li>
					</template>
				</ul>
			</div>
		</div>
  </div>
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon";
import Button from "@/utils/crud/components/Button";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import themeConfig from "@/themeConfig";
import {goToSpecificRoute} from "@/common/helpers/utils";
import {
  mapActions,
  mapGetters,
  mapMutations
} from "vuex";

export default {
	name: "Notifications",
	components: {BaseIcon, Button},
	data() {
		return {
			goToSpecificRoute: goToSpecificRoute,
			icons: themeConfig.icons,
      isMobile: checkIsMobileDevice(),
      areNotificationsVisible: false,
		}
	},
	computed: {
		...mapGetters('dashboard', ['dashboardData', 'dashboardLoaded']),
		...mapGetters('account', ['userData']),
		...mapGetters('lang', ['configData']),

		isDashboardClear() {
			if (this.dashboardData) {
				return (!this.dashboardData.isBanned || !this.userData.isBanned) &&
				this.dashboardData.isVerified &&
				this.dashboardData.messages.counter === 0 &&
				this.dashboardData.subscription.isActive &&
				!this.dashboardData.subscription.isExpiring &&
				!this.dashboardData.isHidden &&
				this.dashboardData.isProfileComplete &&
				!this.dashboardData.description.dashboardMessage;
			} else {
				return false;
			}
		},
	},
  watch: {
    $route (){
      if(checkIsMobileDevice()) {
        this.isMobile = true;
      }
    },
    isDashboardClear: {
      immediate: true,
      handler(val) {
        this.$emit('dashboard-cleared', val);
      }
    }
  },
	methods: {
		...mapActions('dashboard', ['fetchDashboardData']),
		...mapMutations('dashboard', ['clearDashboardData']),

    toggleDropdown() {
      if (this.areNotificationsVisible) {
        this.$root.$emit('hideNotifications')
      } else {
        this.fetchDashboardData();
        this.$root.$emit('showNotifications')
      }
      this.areNotificationsVisible = !this.areNotificationsVisible;
    },


    hide() {
      this.areNotificationsVisible = false;
      this.$root.$emit('hideNotifications')
    },

    getOrderByColor(color) {
			switch (color) {
				case 'red':
				case 'badgeError':
					return 'order-1';
				case 'orange':
				case 'badgeInfo':
					return 'order-2';
				case 'green':
				case 'badgeSuccess':
					return 'order-3';
			}
		},
	},
}
</script>