<template>
	<!--  <div @click="hideAll()">-->
	<div :class="{'hidden' : isChatOpen($route.name)}">
		<!-- MOBILE, LEWA CZESC  -->
		<div v-if="checkIsMobile"
		     :class="$route.name === 'main'?'border-redAccent':'border-black'"
		     class="flex items-center justify-between border-b pl-1 pr-2 bg-white"
		     @click="hideAll()"
		>
			<div class="flex flex-row justify-start gap-5">
				<!-- Logo LG MINUS-->
				<template v-if="$route.name === 'main'">
					<img
							:src=" $route.name === 'main' ? logoHomepage:logo"
							alt="Logo"
							class="w-auto h-12 object-cover"
							@click="scrollToTop"
					/>
				</template>
				<template v-else>
					<router-link to="/#to-top">
						<img
								:src=" $route.name === 'main' ? logoHomepage:logo"
								alt="Logo"
								class="w-auto h-12 object-cover"
						/>
					</router-link>
				</template>
				<!-- Przyciski LG MINUS -->
				<template v-if="userData">
					<!-- Rozmowy LG MINUS -->
					<NavigationItem :tooltip-text="trans('BUTTON:Chats')" class="relative w-min">
						<Button
              :to="{name: 'chats'}"
              icon="chats"
              icon-height="32"
              icon-width="32"
              variant="navLink"
						>
						</Button>
						<div v-if="messagesCounter>0" class="absolute left-6 top-0.5">
							<Badge class="mb-3" variant="counterSuccess">
								{{ messagesCounter > 100 ? '100+' : messagesCounter }}
							</Badge>
						</div>
					</NavigationItem>
					<!-- Nowe znajomosci LG MINUS -->
					<NavigationItem :tooltip-text="trans('BUTTON:NewContacts')" class="relative w-min">
						<Button
              :to="{name: 'search'}"
              icon="newContactsFilled"
              icon-height="32"
              icon-width="32"
              variant="navLink"
						>
						</Button>
					</NavigationItem>
				</template>
			</div>

			<!-- LG MINUS, PRAWA CZESC -->
			<div class="flex flex-row items-center gap-2">
				<!-- Dla niezalogowanego: przycisk Login lub Subscribe -->
				<template v-if="!userData">
          <!-- Selektor jezyka -->
          <LanguageSelect ref="languages"/>
					<!-- LogIn or Subscribe-->
					<NavigationItem>
						<Button
              :text="trans(isAppLaunched?'BUTTON:LogIn':'ABOUT:Subscribe')"
              :to="isAppLaunched?{name: 'auth-login'}:'/#join-us'"
              class="uppercase mr-1"
              variant="buttonRed"
						/>
					</NavigationItem>
				</template>
				<!-- Dla zalogowanego: Dzwonek, Selektor języka,  Hamburger LG MINUS -->
				<template v-if="userData">
					<!-- Powiadomienia -->
					<NavigationItem :tooltip-text="trans(areNotificationsVisible?'BUTTON:MenuHide':'BUTTON:MenuShow')">
						<Notifications ref="notifications"
              @dashboard-cleared="setNotificationsButtonVisible"
						/>
					</NavigationItem>
				</template>
				<!-- Hamburger -->
				<NavigationItem :tooltip-text="trans(isMobileMenuVisible?'BUTTON:MenuHide':'BUTTON:MenuShow')">
					<Button
							class="h-12"
							icon="hamburger"
							variant="navLink"
							@clicked="toggleMobileMenu"
					/>
				</NavigationItem>
			</div>
		</div>

		<!-- Cale menu poziome LG PLUS -->
		<nav v-if="render" ref="nav"
		     class="absolute top-12 lg:top-0 pl-14 lg:p-0 w-full z-20"
		     @click="hideAll()"
		>
			<!-- PELNE MENU LG PLUS z opcjami do wyswietlania po nacisnieciu hamburgera. -->
			<div
        :class="{
          'shadow-lg':$route.name !== 'main' && !$route.name.includes('admin'),
          'border-black': $route.name !== 'main',
          'border-redAccent': $route.name === 'main',
          'hidden': !isMobileMenuVisible && checkIsMobile,
          'en': langLocale === 'en',
          'pl': langLocale === 'pl',
          'uk': langLocale === 'uk',
        }"
					class="flex bg-white border-b
          flex-col    justify-center
          lg:flex-row lg:justify-between lg:px-3"
			>
				<hr class="lg:hidden border-dashed border-grayedDark top-0"/>
				<!-- LG PLUS, hamburger+, LEWA CZESC -->
				<ul class="flex
             flex-col    items-start     px-3    pt-2    gap-0
          lg:flex-row lg:items-center lg:px-0 lg:pt-0 lg:gap-6"
				>
					<!-- Logo LG PLUS-->
					<NavigationItem v-if="!checkIsMobile">
						<template v-if="$route.name === 'main'">
							<img :src="$route.name === 'main'?logoHomepage:isLogoHover ? logoHover : logo" alt="Logo"
							     class="h-12 object-cover"
							     @click="scrollToTop"
							     @mouseleave="isLogoHover = false"
							     @mouseover="isLogoHover = true"
							/>
						</template>
						<template v-else>
							<router-link active-class="" to="/#to-top">
								<img :src="$route.name === 'main'?logoHomepage:isLogoHover ? logoHover : logo" alt="Logo"
								     class="h-12 object-cover"
								     @mouseleave="isLogoHover = false"
								     @mouseover="isLogoHover = true"
								/>
							</router-link>
						</template>
					</NavigationItem>

					<!-- Dla zalogowanego -->
					<!-- Przyciski LG PLUS -->
					<template v-if="!checkIsMobile && userData">
						<NavigationItem>
							<Button
									:text="trans('BUTTON:Chats')"
									:to="{name: 'chats'}"
									icon="chats"
									variant="navLink"
							/>
							<template v-if="messagesCounter>0">
								<Badge class="mb-3 ml-1" tagName="span" variant="counterSuccess">
									{{ messagesCounter > 100 ? '100+' : messagesCounter }}
								</Badge>
							</template>
						</NavigationItem>
						<NavigationItem>
							<Button
									:text="trans('BUTTON:NewContacts')"
									:to="{name: 'search'}"
									icon="newContactsFilled"
									variant="navLink"
							/>
						</NavigationItem>
					</template>

					<!-- Dla niezalogowanego, dla MOBILE i LG+ -->
<!--
					<template v-if="!userData">
						<NavigationItem>
							<Button
									:text="trans('BUTTON:News')"
									:to="{name: 'posts', params: {category: 'news'}}"
									variant="navLink"
							/>
						</NavigationItem>
						<NavigationItem>
							<Button
									:text="trans('BUTTON:FAQ')"
									:to="{name: 'posts', params: {category: 'faq'}}"
									variant="navLink"
							/>
						</NavigationItem>
					</template>
-->
				</ul>

				<!-- LG+ prawa czesc, czyli hamburger+ dolna czesc -->
				<ul class="flex  px-3
             flex-col                    pt-1    pb-3    gap-2
          lg:flex-row lg:items-center lg:pt-0 lg:pb-0 lg:gap-3"
				>
					<NavigationItem v-if="!checkIsMobile && userData"
					                v-show="userData && userData.emailVerifiedAt && isAnyNotification"
					>
						<Notifications ref="notifications"
						               @dashboard-cleared="setNotificationsButtonVisible"
						/>
					</NavigationItem>
					<!-- Selektor jezyka, tylko LG -->
					<NavigationItem v-if="!checkIsMobile && !userData">
						<LanguageSelect ref="languages"/>
					</NavigationItem>

					<template v-if="userData">
						<!-- Edycja profilu -->
						<NavigationItem tooltip-text="TOOLTIP:ProfileEdit">
							<Button
									:icon="hasFeaturedPicture ? null : 'profile'"
									:icon-height="hasFeaturedPicture ?checkIsMobile?24:26:24"
									:icon-width="hasFeaturedPicture ?checkIsMobile?24:26:24"
									:image="hasFeaturedPicture ? userData.featuredPictureUrl : ''"
									:text="trans('BUTTON:ProfileEdit')"
									:to="{name: 'profile', params: {username: null}}"
									hide-text-on-desktop
									variant="icon"
							>
								<template v-if="getOrangeDot">
									<Dot class="-right-4 lg:-right-2" color="orange" settings/>
								</template>
								<template v-if="getRedDot">
									<Dot class="-right-4 lg:-right-2" color="red" settings/>
								</template>
							</Button>
						</NavigationItem>

						<!-- Ustawienia -->
						<NavigationItem tooltip-text="TOOLTIP:Settings">
							<Button
									:text="trans('BUTTON:Settings')"
									:to="{name: 'settings'}"
									hide-text-on-desktop
									icon="settings"
									variant="icon"
							>
								<template v-if="getSettingsDot">
									<Dot
											:color="getSettingsDot"
											class="-right-4 lg:-right-2"
											settings
									/>
								</template>
							</Button>
						</NavigationItem>

						<!-- Panel administratora -->
						<NavigationItem v-if="userData && userData.isAdmin" tooltip-text="TOOLTIP:AdminPanel">
							<Button
									:text="trans('BUTTON:Admin')"
									:to="{name: 'admin-dashboard'}"
									hide-text-on-desktop
									icon="adminPanel"
									variant="icon"
							/>
						</NavigationItem>
						<NavigationSeparator/>
						<!-- Logout -->
						<NavigationItem align="right" class="lg:ml-3">
							<Button
									:text="trans('BUTTON:Logout')"
									class="uppercase border-red-on-disabled"
									variant="buttonRedOutlined"
									@clicked="logoutUser"
							/>
						</NavigationItem>
					</template>

					<!-- Niezalogowany -->
					<template v-else>
						<NavigationSeparator/>
						<!-- Po premierze -->
						<template v-if="isAppLaunched">
							<!-- Register, LG+, hamburger+ -->
							<NavigationItem align="right">
								<Button
										:text="trans('BUTTON:Register')"
										:to="{name: 'auth-register'}"
										class="uppercase border-red-on-disabled"
										variant="buttonRedOutlined"
								/>
							</NavigationItem>
							<!-- Login, tylko LG+-->
							<NavigationItem v-if="!checkIsMobile">
								<Button
										:text="trans('BUTTON:LogIn')"
										:to="{name: 'auth-login'}"
										class="uppercase"
										variant="buttonRed"
								/>
							</NavigationItem>
						</template>
						<!-- Przed premiera -->
						<template v-else>
							<!-- Subskrypcja -->
							<NavigationItem>
								<Button
										:text="trans('ABOUT:Subscribe')"
										class="uppercase"
										to="/#join-us"
										variant="buttonRed"
								/>
							</NavigationItem>
						</template>
					</template>
				</ul>
			</div>
		</nav>
		<!-- Overlay -->
		<div v-if="isOverlayVisible"
      :class="$route.name === 'main'?'border-redAccent':'border-black'"
      class="absolute border-t w-full h-screen bg-black bg-fixed opacity-50 z-10 overscroll-none"
      @click="hideAll()"
		/>
    <!-- Main navigation -->
    <NavigationLanding v-if="$route.name === 'main' && render"/>
		<NavigationAdmin v-if="$route.name.includes('admin') && userData && userData.isAdmin"/>
		<ThreadWatcher v-if="(userData && userData.emailVerifiedAt) && typeof(userData) === 'object' && echoActive"/>
	</div>
</template>

<script>
import themeConfig from "@/themeConfig";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Badge from "@/utils/crud/components/Badge";
import Button from "@/utils/crud/components/Button";
import ThreadWatcher from "@/views/content/messages/ThreadWatcher";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import NavigationItem from "@/layouts/components/navigation/NavigationItem";
import NavigationLanding from "@/layouts/components/navigation/NavigationLanding";
import {isAppLaunched, isChatOpenOnMobile} from "@/common/helpers/utils";
import Notifications from "@/views/content/components/Notifications";
import Dot from "@/utils/dots/Dot";
import Modal from "@/utils/modal/Modal.vue";
import NavigationSeparator from "@/layouts/components/navigation/NavigationSeparator";
import LanguageSelect from "@/views/content/components/LanguageSelect.vue";
import NavigationAdmin from "@/layouts/components/navigation/NavigationAdmin";

export default {
	name: "Navigation",
	components: {
		LanguageSelect,
		NavigationSeparator,
		Badge,
		Button,
		Dot,
		Modal,
		NavigationItem,
		NavigationLanding,
		ThreadWatcher,
		Notifications,
    NavigationAdmin
	},
	data() {
		return {
			config: themeConfig.app,
			isOverlayVisible: false,
			areNotificationsVisible: false,
			areLanguagesVisible: false,
			isMobileMenuVisible: false,
			checkIsMobile: checkIsMobileDevice(),
			logo: themeConfig.app.logo,
			logoHomepage: themeConfig.app.logoHomepage,
			logoHover: themeConfig.app.logoHover,
			isLogoHover: false,
			isAppLaunched: isAppLaunched(),
			messagesCounter: 0,
			isUserDescOrGalleryPending: false,
			hasUserDescOrGallery: false,
			isAnyNotification: false,
		}
	},
	computed: {
		...mapGetters('lang', ['langData', 'langLocale', 'locales', 'langLoaded', 'configData']),
		...mapGetters('chat', ['notReadCounter', 'threadsData', 'currentThread', 'isInputActive', 'echoActive']),
		...mapGetters('account', ['userData', 'authDone']),
		...mapGetters('profile', ['profileData']),

		render() {
			if (this.userDataLocal !== null) {
				return this.authDone;
			}
			return this.langLoaded;
		},

		getSettingsDot() {
			if (!this.userData.isVerified || !this.userData.isSubscriptionActive || this.userData.isBanned
			) {
				return 'red';
			} else if (this.userData.isSubscriptionExpiring || this.userData.isHidden) {
				return 'orange';
			} else {
				return null;
			}
		},

		getOrangeDot() {
			if (this.userData) {
				let userData = this.userData;

				if (this.profileData.length > 1) {
					userData = this.profileData;
				}
				return !this.getRedDot && userData.descriptionEdit.descriptionNewPending;
			}
		},

		getRedDot() {
			if (this.userData) {
				let userData = this.userData;

				if (this.profileData.length > 1) {
					userData = this.profileData;
				}

				return !userData.isGalleryComplete || !userData.isProfileComplete || !userData.isSetupComplete;
			}
		},

		hasFeaturedPicture() {
			return this.userData.hasPictureFeatured;
		},

		userDataLocal() {
			return localStorage.getItem('userData');
		},

	},
	watch: {
		langLocale() {
			if (checkIsMobileDevice()) {
				this.isMobileMenuVisible = false;
				this.isOverlayVisible = false;
			}
			this.areNotificationsVisible = false;
			this.areLanguagesVisible = false;
			this.manageOverlay();

		},
		$route() {
			if (checkIsMobileDevice()) {
				this.isMobileMenuVisible = false;
				this.isOverlayVisible = false;
				this.areNotificationsVisible = false;
				this.areLanguagesVisible = false;
			}
		},
		'notReadCounter.messageCounter'(val) {
			this.messagesCounter = val;
		}
	},
	methods: {
		isChatOpen: isChatOpenOnMobile,
		...mapActions('lang', ['fetchLangData', 'updateLang']),
		...mapActions('account', ['logout', 'authRequest']),
		...mapActions('chat', ['getNotReadCount']),
		...mapMutations('chat', ['setIsCurrentThreadNew', 'clearChatDataOnLogout']),
		...mapMutations('account', ['clearUserData', 'setUserVerified']),
		...mapActions('dashboard', ['fetchDashboardData']),
		...mapMutations('verification', ['setVerificationData']),

		logoutUser() {
			this.logout();
		},

		manageOverlay() {
			this.isOverlayVisible = this.isMobileMenuVisible || this.areNotificationsVisible || this.areLanguagesVisible
		},

		toggleMobileMenu() {
			this.hideNotificationsIfVisible();
			this.hideLanguageIfVisible();
			this.isMobileMenuVisible = !this.isMobileMenuVisible
			this.manageOverlay()
		},

		hideLanguageIfVisible() {
			if(this.areLanguagesVisible) {
				this.areLanguagesVisible = false;
				this.$refs.languages.hide()
			}
		},

		hideNotificationsIfVisible() {
			if(this.areNotificationsVisible) {
				this.areNotificationsVisible = false
				this.$refs.notifications.hide()
			}
		},

		hideMenuIfVisible() {
			if (this.isMobileMenuVisible) {
				this.isMobileMenuVisible = false
			}
		},

		showNotifications() {
			this.hideMenuIfVisible();
			this.hideLanguageIfVisible();

			this.areNotificationsVisible = true
			this.manageOverlay()
		},

		hideNotifications() {
			this.areNotificationsVisible = false
			this.manageOverlay()
		},

		showLanguages() {
			this.hideMenuIfVisible();
			this.hideNotificationsIfVisible();

			this.areLanguagesVisible = true
			this.manageOverlay()
		},

		hideLanguages() {
			this.areLanguagesVisible = false
			this.manageOverlay()
		},

		hideAll() {
			if(this.$refs.notifications) {
				this.$refs.notifications.hide()
			}

			if(this.$refs.languages) {
				this.$refs.languages.hide()
			}

			this.isMobileMenuVisible = false;
			this.manageOverlay()
		},

		setNotificationsButtonVisible(event) {
			this.isAnyNotification = !event;
		},

		scrollToTop() {
			this.$root.$emit('scroll-to-top');
		}
	},

	mounted() {
		this.$root.$on('showNotifications', this.showNotifications);
		this.$root.$on('hideNotifications', this.hideNotifications);

		this.$root.$on('showLanguages', this.showLanguages);
		this.$root.$on('hideLanguages', this.hideLanguages);
	}
}

</script>