<template>
  <div class="absolute top-12 flex w-full items-center justify-center">
    <div class="bg-redAccent border-b border-white items-center flex flex-row flex-wrap lg:flex-nowrap gap-x-2 gap-y-1 lg:gap-6 px-3 lg:px-6 pt-1 pb-2 lg:pb-1 shadow-lg"
      v-scroll-spy-active="{class: 'active-link'}"
      v-scroll-spy-link="{selector: 'button.menu-link'}"
    >
      <Button
        variant="landingLink"
        class="menu-link"
        :text="trans('ABOUT:TopLink')"
      />
      <Button
        variant="landingLink"
        class="menu-link ml-1"
        :text="trans('ABOUT:WhatLink')"
      />
      <Button
        variant="landingLink"
        class="menu-link"
        :text="trans('ABOUT:WhyLink')"
      />
      <Button
        variant="landingLink"
        class="menu-link"
        :text="trans('ABOUT:HowWeLink')"
      />
      <Button
        variant="landingLink"
        class="menu-link"
        :text="trans('ABOUT:HowToLink')"
      />
      <Button
        variant="landingLink"
        class="menu-link"
        :text="trans('ABOUT:AuthorLink')"
      />
      <Button
        variant="landingLink"
        class="menu-link mr-1"
        :text="trans('ABOUT:SubscribeLink')"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/utils/crud/components/Button";
export default {
  name: "LandingNavigation",
  components: {Button}
}
</script>

<style lang="scss">
  .active-link {
    color: theme('colors.white') !important;
    opacity: .3 !important;
    &:hover {
      cursor: default;
    }
  }
</style>