<template>
  <div class="absolute top-12 flex w-full items-center justify-center text-s">
    <div class="w-full bg-black text-white border-t lg:border-t-none border-b border-black items-center flex flex-row flex-wrap gap-x-2 lg:gap-x-6 px-3 lg:px-6 pt-1 pb-2 lg:pb-1 shadow-lg">
      <Button
        :to="{name: 'admin-dashboard'}"
        variant="navLink"
        :text="trans('LABEL:AdminHomepage')"
      />
      |
      <Button
        :to="{name: 'admin-pictures'}"
        :text="trans('LABEL:AdminPictures')"
        variant="navLink"
      />
      <Button
        :to="{name: 'admin-descriptions'}"
        :text="trans('LABEL:AdminDescriptions')"
        variant="navLink"
      />
      <Button
        :to="{name: 'admin-users'}"
        :text="trans('LABEL:AdminUsers')"
        variant="navLink"
      />
      <Button
        :to="{name: 'admin-users-payments'}"
        :text="trans('LABEL:AdminUsersPaymentsList')"
        variant="navLink"
      />
      |
      <NavigationAdminItem>
        <Button
          :to="{name: 'admin-articles'}"
          :text="trans('LABEL:AdminTexts')"
          variant="navLink"
        />
        <template v-slot:plus >
          <Button
            :to="{name: 'admin-articles-new'}"
            text="[+]"
            variant="navLink"
          />
        </template>
      </NavigationAdminItem>
      <NavigationAdminItem>
        <Button
          :to="{name: 'admin-discount-codes'}"
          :text="trans('LABEL:AdminDiscountCodes')"
          variant="navLink"
        />
        <template v-slot:plus >
          <Button
            :to="{name: 'admin-discount-codes-new'}"
            text="[+]"
            variant="navLink"
          />
        </template>
      </NavigationAdminItem>
      <NavigationAdminItem>
        <Button
          :to="{name: 'admin-partners'}"
          :text="trans('LABEL:AdminPartners')"
          variant="navLink"
        />
        <template v-slot:plus>
          <Button
            :to="{name: 'admin-partners-new'}"
            text="[+]"
            variant="navLink"
          />
        </template>
      </NavigationAdminItem>
      <NavigationAdminItem>
        <Button
          :to="{name: 'admin-packages'}"
          :text="trans('LABEL:AdminSubscriptions')"
          variant="navLink"
        />
        <template v-slot:plus>
          <Button
            :to="{name: 'admin-package-new'}"
            text="[+]"
            variant="navLink"
          />
        </template>
      </NavigationAdminItem>
    </div>
  </div>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import NavigationAdminItem from "@/layouts/components/navigation/NavigationAdminItem";

export default {
  name: "LandingNavigation",
  components: {
    Button,
    NavigationAdminItem
  }
}
</script>

<style lang="scss">
  .active-link {
    color: theme('colors.white') !important;
    opacity: .3 !important;
    &:hover {
      cursor: default;
    }
  }
</style>