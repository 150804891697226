<template>
  <div class="flex flex-row gap-1">
    <slot>
      <!-- Content -->
    </slot>
    <slot name="plus">
      <!-- [+] -->
    </slot>
  </div>
</template>
<script>
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";

export default {
  name: "NavigationAdminItem",
	methods: {checkIsMobileDevice},
}
</script>