const hostApi = "https://api.sapioseksualni.pl";
const portApi = 443;

const pusherAuthEndpoint = '/broadcasting/auth'

const baseHost = `${hostApi}${portApi ? `:${portApi}` : ``}`;
const basePath = `/api`;
const baseURLApi = baseHost + basePath;

const broadcastingAuthEndpoint = `${baseURLApi}${pusherAuthEndpoint}`;


const chatColors = [
    {
        text: 'text-white',
        color: 'bg-redAccent',
    },
    {
        text: 'text-white',
        color: 'bg-fuchsia-500',
    },
    {
        text: 'text-white',
        color: 'bg-pink-400',
    },
    {
        text: 'text-black',
        color: 'bg-rose-200',
    },
    {
        text: 'text-black',
        color: 'bg-rose-200',
    },
    {
        text: 'text-black',
        color: 'bg-orange-200',
    },
    {
        text: 'text-white',
        color: 'bg-purple-500',
    },
    {
        text: 'text-white',
        color: 'bg-cyan-600',
    },
    {
        text: 'text-white',
        color: 'bg-teal-600',
    },
    {
        text: 'text-white',
        color: 'bg-emerald-500',
    },
    {
        text: 'text-white',
        color: 'bg-lime-500',
    },
];

export default {
    API_URL: baseURLApi,
    API_HOST: baseHost,
    API_PATH: basePath,
    PUSHER_AUTH_ENDPOINT: broadcastingAuthEndpoint,
    CHAT_COLORS: chatColors,

}
