// initial state
import api from "@/common/services/api";
import toQueryString from "@/common/helpers/toQueryString";
import fromQueryToObject from "@/common/helpers/fromQueryToObject";
import store from '@/store/index'
import router from "@/router";

const state = () => ({
    packagesData: null,
    pagination: {
        currentPage: null,
        from: null,
        lastPage: null,
        perPage: null,
        to: null,
        total: null,
    },
    packagesSaving: false,
    packageData: null,
    packageDeleting: null,
    packageLoaded: false,
    packageDeleted: false,

})

// getters
const getters = {
    packagesData: state => state.packagesData,
    pagination: state => state.pagination,
    packagesSaving: state => state.packagesSaving,
    packageData: state => state.packageData,
    packageDeleting: state => state.packageDeleting,
    packageLoaded: state => state.packageLoaded,
    packageDeleted: state => state.packageDeleted,

};

//actions
const actions = {
    fetchPackagesRequest: ({ commit, dispatch}, payload) => {
        api.setHeader();

        api.query(`/admin/packages`, payload, true)
            .then(
                success => {
                    commit('setPackagesData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    storePackageData: ({commit, dispatch},  payload) => {
        api.setHeader();

        commit('setPackagesSaving', true);
        api.post(`/admin/packages`, payload)
            .then(
                success => {
                    router.push({name: 'admin-subscriptions'});
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setPackagesSaving', false);
        });
    },

    fetchShowPackageRequest: ({ commit, dispatch}, payload) => {
        api.setHeader();
        commit('setPackageLoaded', false);
        api.get(`/admin/packages`, payload, true)
            .then(
                success => {
                    commit('setPackageData', success.data)
                    commit('setPackageLoaded', true);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    updatePackageData: ({commit, dispatch},  payload) => {
        api.setHeader();

        commit('setPackagesSaving', true);
        api.put(`/admin/packages/${payload.id}`, payload)
            .then(
                success => {
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setPackagesSaving', false);
        });
    },

    deletePackageRequest: ({commit, dispatch},  payload) => {
        api.setHeader();

        commit('setPackagesDeleting', true);
        commit('setPackageDeleted', false);
        api.delete(`/admin/packages/${payload.id}`, payload)
            .then(
                success => {
                    commit('setPackageDeleted', true);
                    commit('setPackagesData', success.data.packages);
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setPackagesDeleting', false);
        });
    },


}

//mutations
const mutations = {
    setPackagesData(state, data) {
        state.packagesData = data;
    },

    setPackageData(state, data) {
        state.packageData = data;
    },

    setPagination (state, data) {
        state.pagination = {
            currentPage: data.current_page,
            from: data.from,
            lastPage: data.last_page,
            perPage: data.per_page,
            to: data.to,
            total: data.total,
        }
    },

    setPackagesSaving(state, value) {
        state.packagesSaving = value;
    },

    setPackagesDeleting(state, value) {
        state.packageDeleting = value;
    },

    setPackageLoaded(state, value) {
        state.packageLoaded = value;
    },

    setPackageDeleted(state, value) {
        state.packageDeleted = value;
    },


}

export const packages = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
