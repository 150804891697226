<template>
  <div v-if="render">
    <div class="flex flex-col lg:flex-row justify-around bg-black w-full lg:w-full h-full py-6 space-y-3 lg:space-y-0 text-center">
      <div class="w-full space-y-3">
        <div>
          <Button
              variant="linkFooterWhite"
              :text="trans('BUTTON:Homepage')"
          />
        </div>
        <div>
          <Button
            variant="linkFooterWhite"
            :is-active="isRouteActive('rules')"
            :text="trans('BUTTON:Rules')"
          />
        </div>
      </div>
      <div class="w-full space-y-3">
        <div>
          <Button
              variant="linkFooterWhite"
              :text="trans('BUTTON:News')"
          />
        </div>
        <div>
          <Button
              variant="linkFooterWhite"
              :is-active="isRouteActive('privacy')"
              :text="trans('BUTTON:PrivacyPolicy')"
          />
        </div>
      </div>
      <div class="w-full space-y-3">
        <div>
          <Button
              variant="linkFooterWhite"
              :text="trans('BUTTON:FAQ')"
          />
        </div>
        <div>
          <Button
              variant="linkFooterWhite"
              :is-active="isRouteActive('contact')"
              :text="trans('BUTTON:Contact')"
          />
        </div>
      </div>
    </div>
    <div class="text-center justify-around text-gray-400 text-xs flex flex-row bg-black pb-3 justify-center items-center pt-6">
      <div class="text-center">
        <a class="hover:opacity-50">BrainCamp.pl</a> © {{ year }} | {{ trans('LABEL:Project') }}: <a class="hover:opacity-50">Inkographic Studio</a>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import {mapGetters} from "vuex";
import router from "@/router";

export default {
  name: "Footer",
  components: {Button},
  props: {
    year: Number,
  },
  data() {
    return {
      render: false,
    }
  },
  watch: {
    langLoaded: {
      immediate: true,
      handler(val) {
        if (val) {
          this.render = true;
        }
      }
    }
  },
  computed: {
    ...mapGetters('lang', ['langLoaded']),
    ...mapGetters('dictionaries', ['categoriesData']),
    getContactPage() {
      if(this.categoriesData) {
        return this.categoriesData.find(el => el.slug === 'contact');
      }
    },
    getPrivacyPolicyPage() {
      if(this.categoriesData) {
        return this.categoriesData.find(el => el.slug === 'privacy');
      }
    },
    getRulesPage() {
      if(this.categoriesData) {
          return this.categoriesData.find(el => el.slug === 'rules');
      }
    },
  },
  methods: {
    goToCategory(category) {
      if(category.hasOwnProperty('onlyPostSlug')) {
        router.push({name: 'post-show', params: {category: category.slug, slug: category.onlyPostSlug}})
        return;
      }
      router.push({name: 'posts', params: {category: category.slug}})
    },
    isRouteActive(slug) {
      if(this.$route.params.category) {
        return this.$route.params.category.includes(slug);
      }
      return false;
    }
  }

}
</script>

.$route.params.category.includes(slug);
      }
      return false;
    }
  }

}
</script>

g, slug: category.onlyPostSlug}})
        return;
      }
      router.push({name: 'posts', params: {category: category.slug}})
    },
    isRouteActive(slug) {
      if(this.$route.params.category) {
        return this.$route.params.category.includes(slug);
      }
      return false;
    }
  }

}
</script>

