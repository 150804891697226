<template>
  <component
        :is="resolveLayoutVariant"
    >
        <template v-if="langLoaded || (userData !== null && authDone)">
          <transition name="fade" mode="out-in">
            <router-view :key="$route.name"></router-view>
          </transition>
        </template>
      <toast position="se"></toast>
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import { Toast } from 'vuex-toast'
import { useRouter } from '@/common/helpers/utils'

// Layouts
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import themeConfig from "@/themeConfig";
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import config from "@/config";
import Echo from 'laravel-echo';
import { localize } from 'vee-validate';

export default {
  components: {
    LayoutContentHorizontalNav,
    LayoutBlank,
    Toast
  },

  computed: {
    ...mapGetters('lang', ['langData', 'langLocale', 'locales', 'langLoaded']),
    ...mapGetters('account', ['accessToken', 'authDone', 'userData']),
    ...mapGetters('chat', ['echoActive']),
  },
  watch: {
    langLocale(val) {
      localize(val);
      this.fetchOrientationData();
      this.fetchDescriptionStatuses();
      this.fetchRatesData();
      this.fetchExperiencesData();
      this.fetchSearchWhosData();
      this.fetchSearchWhatsData();
			this.fetchGenderDeclaredData();
			this.fetchGenders();

			if(this.userData) {
				this.fetchStimulantsAndAnimalsData();
				this.fetchShapeData();
				this.fetchPreferenceData();
			}
    },
    authDone(val) {
      if(val && this.userData.hasOwnProperty('emailVerifiedAt') && this.userData.emailVerifiedAt !== null) {
        this.getNotReadCount();
        this.fetchDashboardData();
        this.fetchShapeData();
        this.fetchPreferenceData();
        this.fetchOrientationData();
        this.fetchDescriptionStatuses();
        this.fetchRatesData();
        this.fetchExperiencesData();
        this.fetchSearchWhosData();
        this.fetchSearchWhatsData();
				this.fetchStimulantsAndAnimalsData();

        window.Echo.encryptedPrivate(`verification-status.${this.userData.username}`).listen('VerificationStatus', (e) => {
          this.setVerificationData(e.verificationData);
          this.setUserVerification(e.verificationData);
          if(e.verificationData.verified) {
            this.setUserVerified(true);
          }
          if(this.$route.name === 'verification-details') {
            this.fetchVerificationDetails();
          }
        });

	      window.Echo.encryptedPrivate(`user-status.${this.userData.username}`).listen('UserBanned', (e) => {
		      this.updateUserBannedStatus(e);
					this.fetchDashboardData();
	      });
      }
    }
  },
  methods: {
    ...mapActions('lang', ['fetchLangData', 'updateLang']),
    ...mapActions('account', ['authRequest']),
    ...mapActions('dashboard', ['fetchDashboardData']),
    ...mapMutations('account', ['setUserData', 'setToken', 'setUserVerified', 'setUserVerification']),
    ...mapMutations('chat', ['setEchoActive']),
    ...mapActions('chat', ['getNotReadCount']),
    ...mapActions('alert', ['success']),
	  ...mapActions('subscriptions', ['fetchCurrentSubscriptionData']),
    ...mapActions('verification', ['fetchVerificationDetails']),
    ...mapActions('dictionaries', [
        'fetchRatesData', 'fetchLocationData', 'fetchExperiencesData', 'fetchParentalData', 'fetchMaritalData',
        'fetchDescriptionStatuses', 'fetchShapeData', 'fetchOrientationData', 'fetchSearchWhatsData', 'fetchSearchWhosData',
        'fetchPreferenceData', 'fetchGenderDeclaredData', 'fetchGenders', 'fetchCategoriesData', 'fetchStimulantsAndAnimalsData'
    ]),
    ...mapMutations('verification', ['setVerificationData']),
    ...mapMutations('account', ['updateUserBannedStatus']),
    updateLanguageAction(lang) {
      this.fetchLangData(lang);
    },
    setViewHeight: function() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
  },
  beforeUpdate() {
    if(this.accessToken !== null && !this.echoActive) {
      window.Pusher = require('pusher-js/with-encryption');

      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_PUSHER_KEY,
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        authEndpoint: config.PUSHER_AUTH_ENDPOINT,
        auth: {
          headers: {
            Authorization: `Bearer ${this.accessToken}`
          }
        },
        encrypted: true
      });
      this.setEchoActive(true);
    }
  },
  mounted() {
    this.setViewHeight();
    this.fetchCategoriesData();
	  this.fetchGenderDeclaredData();
	  this.fetchGenders();

    window.addEventListener('resize', () => {
      this.setViewHeight();
    });
    if(this.$route.query.emailVerified) {
      this.success({message: 'Konto zostało aktywowane'})
    }
    localize(localStorage.getItem('langLocale'));
  },
  beforeMount() {
    this.fetchLangData();
	  if(localStorage.getItem('userData')) {
		  this.setToken(localStorage.getItem('accessToken') ?? 'pl');
		  this.authRequest();
	  }
  },

  setup() {
    const { route } = useRouter()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-horizontal-nav`

      return null
    })

    return {
      resolveLayoutVariant,

    }
  },
}
</script>
