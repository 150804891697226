const hostApi = process.env.NODE_ENV === "development" ? "http://localhost" : "https://domain.com";
const portApi = process.env.NODE_ENV === "development" ? 8083 : 80;

const pusherKey = '';
const pusherCluster = 'eu';
const pusherAuthEndpoint = '/broadcasting/auth'

const baseHost = `${hostApi}${portApi ? `:${portApi}` : ``}`;
const basePath = `/api`;
const baseURLApi = baseHost + basePath;

const broadcastingAuthEndpoint = `${baseURLApi}${pusherAuthEndpoint}`;

export default {
    API_URL: baseURLApi,
    API_HOST: baseHost,
    API_PATH: basePath,
    PUSHER_KEY: pusherKey,
    PUSHER_CLUSTER: pusherCluster,
    PUSHER_AUTH_ENDPOINT: broadcastingAuthEndpoint,
}
