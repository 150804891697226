// initial state
import api from "@/common/services/api";
import toQueryString from "@/common/helpers/toQueryString";
import fromQueryToObject from "@/common/helpers/fromQueryToObject";
import store from '@/store/index'
import router from "@/router";

const state = () => ({
    discountCodesData: null,
    pagination: {
        currentPage: null,
        from: null,
        lastPage: null,
        perPage: null,
        to: null,
        total: null,
    },
    discountCodesSaving: false,
    discountCodeData: null,
    discountCodeDeleting: null,
    discountCodeLoaded: false,
    discountCodeDeleted: false,

})

// getters
const getters = {
    discountCodesData: state => state.discountCodesData,
    pagination: state => state.pagination,
    discountCodesSaving: state => state.discountCodesSaving,
    discountCodeData: state => state.discountCodeData,
    discountCodeDeleting: state => state.discountCodeDeleting,
    discountCodeLoaded: state => state.discountCodeLoaded,
    discountCodeDeleted: state => state.discountCodeDeleted,

};

//actions
const actions = {
    fetchDiscountCodesRequest: ({ commit, dispatch}, payload) => {
        api.setHeader();

        api.query(`/admin/discount-codes`, payload, true)
            .then(
                success => {
                    commit('setDiscountCodesData', success.data)
                    commit('setPagination', success.meta)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    storeDiscountCodeData: ({commit, dispatch},  payload) => {
        api.setHeader();

        commit('setDiscountCodesSaving', true);
        api.post(`/admin/discount-codes`, payload)
            .then(
                success => {
                    router.push({name: 'admin-discount-codes'});
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setDiscountCodesSaving', false);
        });
    },

    fetchShowDiscountCodeRequest: ({ commit, dispatch}, payload) => {
        api.setHeader();
        commit('setDiscountCodeLoaded', false);
        api.get(`/admin/discount-codes`, payload, true)
            .then(
                success => {
                    commit('setDiscountCodeData', success.data)
                    commit('setDiscountCodeLoaded', true);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    updateDiscountCodeData: ({commit, dispatch},  payload) => {
        api.setHeader();

        commit('setDiscountCodesSaving', true);
        api.put(`/admin/discount-codes/${payload.id}`, payload)
            .then(
                success => {
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setDiscountCodesSaving', false);
        });
    },

    deleteDiscountCodeRequest: ({commit, dispatch},  payload) => {
        api.setHeader();

        commit('setDiscountCodesDeleting', true);
        commit('setDiscountCodeDeleted', false);
        api.delete(`/admin/discount-codes/${payload.id}`, payload)
            .then(
                success => {
                    commit('setDiscountCodeDeleted', true);
                    commit('setDiscountCodesData', success.data.discountCodes);
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setDiscountCodesDeleting', false);
        });
    },


}

//mutations
const mutations = {
    setDiscountCodesData(state, data) {
        state.discountCodesData = data;
    },

    setDiscountCodeData(state, data) {
        state.discountCodeData = data;
    },

    setPagination (state, data) {
        state.pagination = {
            currentPage: data.current_page,
            from: data.from,
            lastPage: data.last_page,
            perPage: data.per_page,
            to: data.to,
            total: data.total,
        }
    },

    setDiscountCodesSaving(state, value) {
        state.discountCodesSaving = value;
    },

    setDiscountCodesDeleting(state, value) {
        state.discountCodeDeleting = value;
    },

    setDiscountCodeLoaded(state, value) {
        state.discountCodeLoaded = value;
    },

    setDiscountCodeDeleted(state, value) {
        state.discountCodeDeleted = value;
    },


}

export const discountCodes = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
