const posts = [
    {
        path: '/posts',
        component: () => import('@/views/content/posts/ReadingRoom.vue'),
        children: [
            {
                path: '/posts/:category?',
                name: 'posts',
                component: () => import('@/views/content/posts/Posts.vue'),
                meta: {
                    layout: 'content',
                    auth: false,
                    emailVerified: false,
                    allowBanned: true,
                },
            },
            {
                path: '/posts/:category/:slug',
                name: 'post-show',
                component: () => import('@/views/content/posts/PostShow.vue'),
                meta: {
                    layout: 'content',
                    auth: false,
                    emailVerified: false,
                    allowBanned: true,
                },
            },
        ],
    },

]

export default posts
