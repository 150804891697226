const users = [
    {
        path: '/admin/users',
        name: 'admin-users',
        component: () => import('@/views/content/admin/users/UsersList.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },
    {
        path: '/admin/users/payments',
        name: 'admin-users-payments',
        component: () => import('@/views/content/admin/users/UsersPaymentsList.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },
]

export default users;