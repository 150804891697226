import Vue from "vue";
import store from '@/store/index'

const ApiService = {
    axios: null,
    counter: 0,

    init(showLoader = false) {
        this.axios = Vue.axios;
        this.axios.defaults.showLoader = showLoader;
        this.axios.defaults.responseType = "";
        this.axios.interceptors.request.use(
            config => {

                if (config.showLoader) {
                    store.dispatch('loader/pending', null, {root: true});
                }
                return config;
            },
            error => {
                if (error.config.showLoader) {
                    store.dispatch('loader/done', null, {root: true});
                }
                return Promise.reject(error);
            }
        );
        this.axios.interceptors.response.use(
            response => {

                if (response.config.showLoader) {
                    store.dispatch('loader/done', null, {root: true});
                }
                return response;
            },
            error => {

                let response = error.response;

                if (response.config.showLoader) {
                    store.dispatch('loader/done', null, {root: true});
                }
                if(error.response.status === 401) {
                    store.dispatch('account/logout', false,{root: true})
                    store.dispatch('alert/error', {message: error.response.data.message} ,{root: true})
                    return;
                }
                return Promise.reject(error);
            }
        )
    },

    setHeader(uploadImages = false) {
        this.setLocale();

        this.axios.defaults.headers.common["Authorization"] = `Bearer ${store.getters['account/accessToken']}`;
        if(uploadImages) {
            this.axios.defaults.headers["Content-Type"] = `multipart/form-data`;
        }
    },

    setLocale() {
        this.axios.defaults.headers['Accept-Language'] = store.getters['lang/langLocale'];
    },

    query(resource, payload, showLoader = false) {
        this.init(showLoader);

        return this.axios.get(resource, {params: payload}).then(function (response) {
            return response.data;
        }).catch(function (error) {
            if(error.hasOwnProperty('response')) {
                return Promise.reject(error.response.data);
            }
        });
    },

    getImage(resource, slug = "", showLoader = false) {
        this.init(showLoader);
        this.axios.defaults.responseType = "arraybuffer";

        return this.axios.get(`${resource}/${slug}`).then(function (response) {
            return response;
        }).catch(function (error) {
            if(error.hasOwnProperty('response')) {
                return Promise.reject(error.response.data);
            }
        });
    },

    get(resource, slug = "", showLoader = false) {
        this.init(showLoader);

        return Vue.axios.get(`${resource}/${slug}`).then(function (response) {
            return response.data;
        }).catch(function (error) {
            if(error.hasOwnProperty('response')) {
                return Promise.reject(error.response.data);
            }
        });
    },

    post(resource, params) {
        this.init();

        return this.axios.post(`${resource}`, params).then(function (response) {
             return response.data;
        }).catch(function (error) {
            if(error.hasOwnProperty('response')) {
                return Promise.reject(error.response.data);
            }
        });
    },

    patch(resource, params) {
        this.init();

        return this.axios.patch(`${resource}`, params).then(function (response) {
            return response.data;
        }).catch(function (error) {
            return Promise.reject(error.response.data);
        });
    },

    put(resource, params) {
        this.init();

        return this.axios.put(`${resource}`, params).then(function (response) {
            return response.data;
        }).catch(function (error) {
            return Promise.reject(error.response.data);
        });
    },

    delete(resource) {
        this.init();

        return this.axios.delete(`${resource}`).then(function (response) {
            return response.data;
        }).catch(function (error) {
            return Promise.reject(error.response.data);
        });
    }
};

export default ApiService;