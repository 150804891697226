<template>
  <div
      :class="{
        'px-0': $route.name === 'main',
        'lg:px-10 xl:px-2xl 2xl:px-3xl 3xl:px-3xl 4xl:px-4xl 5xl:px-5xl 6xl:px-6xl': $route.name !== 'main'
      }"
      class="mx-auto z-10 w-full"
  >
    <slot></slot>
  </div>
</template>

<script>

export default {
  mounted(){
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss">

</style>
