<template>

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'


export default {
  name: "ThreadWatcher",
  data() {
    return {
      audio: new Audio(require('../../../assets/sounds/new_message_sound.mp3'))
    }
  },
  computed: {
    ...mapGetters('chat', ['notReadCounter', 'threadsData', 'currentThread', 'isInputActive']),
    ...mapGetters('account', ['userData']),
	  ...mapGetters('dashboard', ['dashboardData'])
  },
  watch: {

  },
  methods: {
    ...mapActions('lang', ['fetchLangData', 'updateLang']),
    ...mapActions('account', ['logout', 'authRequest']),
    ...mapActions('chat', ['getNotReadCount']),
	  ...mapActions('dashboard', ['fetchDashboardData']),
    ...mapMutations('chat', ['setIsCurrentThreadNew', 'clearChatDataOnLogout', 'setNotReadMessagesCounter']),
	  ...mapMutations('dashboard', ['updateDashboardMessagesData']),
	  ...mapMutations('account', ['updateUserDataDashboardColor']),

    updateNavbarCounter(e) {
      this.setNotReadMessagesCounter(e.unreadMessages);
    },

    updateThreadCounter(e) {
      this.notReadCounter.threadsCounter.filter(el => {
        if(el.threadId === e.threadId) {
          el.messagesNotRead = e.threadUnreadMessages;
        }
      })
    },

    updateLatestMessage(e) {
      this.threadsData.filter(el => {
        if(el.id === e.threadId) {
          el.is_read = false;
          el.latestMessage = e.lastMessage;
          el.latestMessageDate = e.lastMessageDate;
          el.lastMessageSender = e.lastMessageSender;
          el.lastMessageCreatedAt = e.lastMessageCreatedAt;
        }
      })
    },

    playNotificationSound() {
      this.audio.play();
    },

    changeNewMessagesCounter(e) {
      this.notReadCounter.messageCounter = e.unreadMessages;
      this.notReadCounter.threadsCounter.filter(el => {
        if(el.threadId === e.threadId) {
          el.messagesNotRead = e.threadUnreadMessages;
          this.threadsData.find(el => el.id === e.threadId).is_read = true;
        }
      })
    },

    pushNewThread(e) {
      if(!this.threadsData.some(el => el.id === e.threadId)) {
        this.threadsData.unshift({
          id: e.threadId,
          is_read: false,
          latestMessage: e.lastMessage,
          latestMessageDate: e.lastMessageDate,
	        lastMessageCreatedAt: e.lastMessageCreatedAt,
          ownerUser: e.owner,
          targetUser: e.receiver,
          bannedAt: null,
          color: e.color,
          isAdminMessage: e.isAdminMessage,
          userFavouritedBy: e.userFavouritedBy,
          systemMessage: null,
          featuredPictureUrl: e.featuredPictureUrl,
        });
        this.notReadCounter.threadsCounter.unshift({
          threadId: e.threadId,
          messagesNotRead: 1,
        });
      }
    },

    sortThreadsByDate() {
      this.threadsData.sort((a,b)=> {
				return Date.parse(b.lastMessageCreatedAt) - Date.parse(a.lastMessageCreatedAt);
			});

    }
  },
  mounted() {
    // Listen new messages coming
    window.Echo.encryptedPrivate(`messages.${this.userData.id}`)
        .listen('MessagesCounter', (e) => {
          this.playNotificationSound();
          // If user has chosen thread and has opened chat window and has focus on message input - don't change new messages counter
          if(!this.currentThread || (this.currentThread && (e.threadId !== this.currentThread.id)) || !this.isInputActive) {
            // Update counter in navbar
            this.updateNavbarCounter(e);

            // Update thread counter
            this.updateThreadCounter(e);

            // Update the latest message and state in threads
            this.updateLatestMessage(e);

            // Push new thread if not yet visible
            this.pushNewThread(e);

						this.fetchDashboardData();
          }

          this.sortThreadsByDate();

        })
        // Change new messages counter if message is read
        .listen('MessageRead', (e) => {
          this.changeNewMessagesCounter(e)
	        this.fetchDashboardData();
        })

			  this.$root.$on('sort-threads', () => {
				  this.sortThreadsByDate();
			  })
  },

	beforeDestroy() {
		this.$root.$off('sort-threads')
	}
}
</script>