import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import app from './app/index'
import TokenService from "@/common/services/token";
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  ...app,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          document.getElementById(to.hash).scrollIntoView({ behavior: "smooth" });
        }, 100)
      })
      // This ensures that if hash is provided to router.push it works as expected.
      //  & since we have used "behavior: 'smooth'" the browser will slowly come to this hash position.
    }
    return { x: 0, y: 0 }
  },
})


router.beforeEach( (to, _, next) => {
  const userData = store.getters['account/userData'] !== null ? store.getters['account/userData'] : JSON.parse(localStorage.getItem('userData'))
  const emailVerified = userData?.emailVerifiedAt;
  const userBanned = userData?.isBanned;
  const userToken = TokenService.getToken();

  const isLoggedIn = Boolean(userData && userToken);

  const isVerified = Boolean(userData && emailVerified);

  const isBanned = Boolean(userData && userBanned);

  if(to.meta.auth && !isLoggedIn) {
    next({name: 'auth-login'})
  }

  if(to.meta.auth && to.meta.emailVerified && !emailVerified) {
    next({name: 'auth-verify-email'})
  }

  if (!to.meta.redirectIfLoggedIn && to.meta.redirectIfVerified && isVerified) {
    next('/')
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  if(to.matched && to.matched[0].path === '/admin' && !userData.isAdmin){
    next({name: 'main'})
  }

  return next()
})

export default router
