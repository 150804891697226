// initial state
import api from "@/common/services/api";
import toQueryString from "@/common/helpers/toQueryString";
import fromQueryToObject from "@/common/helpers/fromQueryToObject";
import store from '@/store/index'
import router from "@/router";

const state = () => ({
    partnersData: null,
    pagination: {
        currentPage: null,
        from: null,
        lastPage: null,
        perPage: null,
        to: null,
        total: null,
    },
    partnersSaving: false,
    partnerData: null,
    partnerDeleting: null,
    partnerLoaded: false,
    partnerDeleted: false,
    partnersSelectData: null,

})

// getters
const getters = {
    partnersData: state => state.partnersData,
    pagination: state => state.pagination,
    partnersSaving: state => state.partnersSaving,
    partnerData: state => state.partnerData,
    partnerDeleting: state => state.partnerDeleting,
    partnerLoaded: state => state.partnerLoaded,
    partnerDeleted: state => state.partnerDeleted,
    partnersSelectData: state => state.partnersSelectData,

};

//actions
const actions = {
    fetchPartnersRequest: ({ commit, dispatch}, payload) => {
        api.setHeader();

        api.query(`/admin/partners`, payload, true)
            .then(
                success => {
                    commit('setPartnersData', success.data)
                    commit('setPagination', success.meta)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchPartnersSelectRequest: ({ commit, dispatch}) => {
        api.setHeader();

        api.query(`/admin/partners/all`, '', true)
            .then(
                success => {
                    commit('setPartnersSelectData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    storePartnerData: ({commit, dispatch},  payload) => {
        api.setHeader();

        commit('setPartnersSaving', true);
        api.post(`/admin/partners`, payload)
            .then(
                success => {
                    router.push({name: 'admin-partners'});
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setPartnersSaving', false);
        });
    },

    fetchShowPartnerRequest: ({ commit, dispatch}, payload) => {
        api.setHeader();
        commit('setPartnerLoaded', false);
        api.get(`/admin/partners`, payload, true)
            .then(
                success => {
                    commit('setPartnerData', success.data)
                    commit('setPartnerLoaded', true);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    updatePartnerData: ({commit, dispatch},  payload) => {
        api.setHeader();

        commit('setPartnersSaving', true);
        api.put(`/admin/partners/${payload.id}`, payload)
            .then(
                success => {
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setPartnersSaving', false);
        });
    },

    deletePartnerRequest: ({commit, dispatch},  payload) => {
        api.setHeader();

        commit('setPartnersDeleting', true);
        commit('setPartnerDeleted', false);
        api.delete(`/admin/partners/${payload.id}`, payload)
            .then(
                success => {
                    commit('setPartnerDeleted', true);
                    commit('setPartnersData', success.data.partners);
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setPartnersDeleting', false);
        });
    },


}

//mutations
const mutations = {
    setPartnersData(state, data) {
        state.partnersData = data;
    },

    setPartnersSelectData(state, data) {
        state.partnersSelectData = data;
    },

    setPartnerData(state, data) {
        state.partnerData = data;
    },

    setPagination (state, data) {
        state.pagination = {
            currentPage: data.current_page,
            from: data.from,
            lastPage: data.last_page,
            perPage: data.per_page,
            to: data.to,
            total: data.total,
        }
    },

    setPartnersSaving(state, value) {
        state.partnersSaving = value;
    },

    setPartnersDeleting(state, value) {
        state.partnerDeleting = value;
    },

    setPartnerLoaded(state, value) {
        state.partnerLoaded = value;
    },

    setPartnerDeleted(state, value) {
        state.partnerDeleted = value;
    },


}

export const partners = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
