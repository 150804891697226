const partners = [
    {
        path: '/admin/partners',
        name: 'admin-partners',
        component: () => import('@/views/content/admin/partners/PartnersList.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },
    {
        path: '/admin/partners/new',
        name: 'admin-partners-new',
        component: () => import('@/views/content/admin/partners/PartnerShow.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },
    {
        path: '/admin/partners/:id',
        name: 'admin-partners-show',
        component: () => import('@/views/content/admin/partners/PartnerShow.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },

]

export default partners;
