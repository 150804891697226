<template>
  <div>
    <layout-content>
      <!-- Default Slot -->
      <slot></slot>

      <!-- Navbar -->
      <template #navbar>
        <Navigation />
      </template>

      <!-- Slot: footer -->
      <template #footer>
        <Footer :class="{'hidden': ($route.name === 'chats' || $route.name === 'search') && isMobile}" :year="year" />
      </template>

    </layout-content>
  </div>
</template>

<script>
import LayoutContent from "@/layouts/components/LayoutContent";

import themeConfig from '@/themeConfig.js'
import { mdiHeartOutline } from '@mdi/js'
import {mapGetters} from "vuex";

import Button from "@/utils/crud/components/Button";
import Navigation from "@/layouts/components/navigation/Navigation";
import Footer from "@/layouts/components/footer/Footer";
import Loader from "@/views/Loader";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";

export default {
  components: {
    Button,
    Footer,
    LayoutContent,
    Loader,
    Navigation,
  },
  computed: {
    ...mapGetters('account', ['userData']),
    ...mapGetters('chat', ['notReadCounter']),
    isMobile() {
      return checkIsMobileDevice();
    }
  },
  setup() {

    const year = new Date().getFullYear();

    return {

      // Search
      year,

      // App Config
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Icons
      icons: {
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  .app-title {
    font-size: 1.25rem;
    font-weight: 600;
  }

  .navbar-content-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    position: relative;
  }
</style>
