// initial state
import api from "@/common/services/api";
import toQueryString from "@/common/helpers/toQueryString";
import fromQueryToObject from "@/common/helpers/fromQueryToObject";
import store from '@/store/index'
import router from "@/router";

const state = () => ({
    usersData: [],
    loading: false,
    loaded: false,
    pagination: {
        currentPage: null,
        from: null,
        lastPage: null,
        perPage: null,
        to: null,
        total: null,
    },
    filters: null,
    favFilters: null,
    favFiltersLoading: false,
    savedFilters: false,
    updatedFilter: false,
    activeFilter: null,
    isShowEditFilterNameModal: false,
    filterToEdit: null,
    isInitialSearch: true,
    lastPage: null,
    backFromNewContacts: false,

})

// getters
const getters = {
    usersData: state => state.usersData,
    usersLoading: state => state.loading,
    usersLoaded: state => state.loaded,
    pagination: state => state.pagination,
    userFilters: state => state.filters,
    favFilters: state => state.favFilters,
    favFiltersLoading: state => state.favFiltersLoading,
    savedFilters: state => state.savedFilters,
    activeFilter: state => state.activeFilter,
    isShowEditFilterNameModal: state => state.isShowEditFilterNameModal,
    filterToEdit: state => state.filterToEdit,
    updatedFilter: state => state.updatedFilter,
    isInitialSearch: state => state.isInitialSearch,
    lastPage: state => state.lastPage ? Number(state.lastPage) : state.lastPage,
    backFromNewContacts: state => state.backFromNewContacts,
};

//actions
const actions = {
    fetchUsersData: ({ commit, getters, state, dispatch}, payload = null) => {

        commit('setLoading', true);
        commit('setLoaded', false);

        let page = payload !== null ? payload.page : 1;

        if(state.filters) {
            payload = {...state.filters};
            payload.page = router.currentRoute.query.page && getters.backFromNewContacts ? Number(router.currentRoute.query.page) : page;
        }

        if(!payload) {
            payload = {};
        }

        if(state.isInitialSearch) {
            payload.init_search = true;
        } else {
            delete payload.init_search;
        }

        api.setHeader();
        api.query(`/users`, payload)
            .then(
                success => {
                    commit('setUsersData', success.data)
                    commit('setPagination', success.meta)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setLoading', false);
                commit('setLoaded', true);
        });
    },

    setPage({commit}, value) {
        commit('setCurrentPage', value);
    },

    saveFavFiltersRequest: ({ commit, dispatch, state }, data) => {
        commit('setSavingFilters', true);
        commit('setSavedFilters', false);
        commit('setUpdatedFilter', false);

        let payload = {
            query: toQueryString(state.filters),
            filterName: data.filterName,
            id: data?.id
        }
        api.setHeader();
        api.post('/update-favourite-filters', payload)
            .then(
                success => {
                    commit('account/setUserFavFilters', success.data.filters, {root: true})
                    if(!success.data.isUpdated) {
                        commit('setActiveFilter', success.data.filters.find(el => el.name === payload.filterName)?.id);
                        commit('setSavedFilters', true);
                    } else {
                        commit('setUpdatedFilter', true);
                    }
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setSavingFilters', false);
        });
    },

    removeFavouriteFilter: ({commit, dispatch}, payload) => {
        api.setHeader();
        api.delete(`/remove-favourite-filter/${payload}`)
            .then(
                success => {
                    commit('account/setUserFavFilters', success.data.filters, {root: true})
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setSavingFilters', false);
        });
    }
}

//mutations
const mutations = {
    setUsersData (state, data) {
        state.usersData = data;
    },
    setPagination (state, data) {
        if(data) {
            state.pagination = {
                currentPage: data.current_page,
                from: data.from,
                lastPage: data.last_page,
                perPage: data.per_page,
                to: data.to,
                total: data.total,
            }
        }
    },
    setCurrentPage (state, value) {
        if(state.pagination) {
            state.pagination.currentPage = value;
        }
    },
    setLoading (state, value) {
        state.loading = value;
    },
    setLoaded (state, value) {
        state.loaded = value;
    },
    setFilters (state, filters) {
        state.filters = filters;
    },
    setFavFilters (state, data) {
        state.favFilters = data.filters;
        state.activeFilter = data.id;
    },
    setActiveFilter (state, id) {
        state.activeFilter = id;
    },
    setSavingFilters (state, value) {
        state.favFiltersLoading = value;
    },
    setSavedFilters(state, value) {
        state.savedFilters = value;
    },
    clearFilters (state) {
        state.filters = null;
    },
    unsetActiveFilter (state) {
        state.activeFilter = null;
    },
    unsetFavouriteFilters (state) {
        state.favFilters = null;
    },
    setIsShowEditFilterNameModal(state, value) {
        state.isShowEditFilterNameModal = value;
    },
    setFilterToEdit(state, id) {
        state.filterToEdit = id;
    },
    setUpdatedFilter(state, value) {
        state.updatedFilter = value;
    },
    setIsInitialSearch(state, value) {
        state.isInitialSearch = value;
    },
    setLastPage(state, page) {
        state.lastPage = page;
    },
    setBackFromNewContacts(state, value) {
        state.backFromNewContacts = value;
    },
    setUserProfileThreadId(state, data) {
        state.usersData.find(el => el.username === data.username).threadId = data.threadId;
    },

}

export const users = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
