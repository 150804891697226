<template>
  <t-modal
      :variant="variant"
      ref="modal"
      v-model="showModalDialog"
  >
    <template v-slot:header>
      <slot name="header">
        {{ header }}
      </slot>
    </template>
    <slot name="content">
    </slot>
    <template v-slot:footer v-if="!hideFooter">
      <slot name="footer">

      </slot>
      <template v-if="!$slots.footer">
        <Button
          variant="buttonRedOutlined"
          :text="buttonCancelText ? buttonCancelText : trans('BUTTON:Cancel')"
          @clicked="cancelAction"
        >
        </Button>
        <Button
          variant="buttonRed"
          btn-type="submit"
          :loading="loading"
          :disabled="confirmDisabled"
          :text="buttonConfirmText ? buttonConfirmText : trans('BUTTON:Save')"
          @clicked="confirmAction"
        >
        </Button>
      </template>
    </template>
  </t-modal>
</template>

<script>
import Button from "@/utils/crud/components/Button";


export default {
  name: "Modal",
  components: {Button},
  props: {
    loading: Boolean,
    disabled: Boolean,
    buttonConfirmText: String,
    showModal: Boolean,
    confirmDisabled: Boolean,
    showCancelButton: {
      default: true,
      type: Boolean,
    },
    variant: String,
    header: String,
    buttonCancelText: String,
    data: Object,
    hideFooter: Boolean,
  },
  data() {
    return {
    }
  },
  computed: {
    showModalDialog: {
      get: function () {
        return this.showModal;
      },
      set: function (value) {
        if (value === false) {
          this.$emit('clicked');
        }
        return value;
      }
    }
  },
  methods: {
    confirmAction() {
      this.$emit('onConfirm');
    },
    cancelAction() {
      this.showModalDialog = false;
    }
  },
  setup() {
    return {

    }
  }
}
</script>
